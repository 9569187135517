import React from "react";
import FAQ from "./FAQ";
import Prices from "./Prices";

export default function ViewPrices() {
  return (
    <>
      <Prices />
      <FAQ />
      <div className="container mx-auto px-4 py-8">
        <h2 className="text-2xl font-bold mb-6">
          Lo que opinan nuestros usuarios
        </h2>
        <div className="flex flex-col md:flex-row justify-center md:space-x-4 p-8">
          <div className="bg-white p-6 rounded-lg shadow-lg mb-4 md:mb-0">
            <div className="flex items-center mb-4">
              <i className="fas fa-star text-yellow-400"></i>
              <i className="fas fa-star text-yellow-400"></i>
              <i className="fas fa-star text-yellow-400"></i>
              <i className="fas fa-star text-yellow-400"></i>
              <i className="fas fa-star text-yellow-400"></i>
            </div>
            <p className="mb-4">
              "The platform is {" "}
              <span className="underline decoration-sky-500">
                intuitive and very easy to use
              </span> {" "}
              , and the results are immediate. Highly recommended!"
            </p>
            <p className="font-bold">José R.</p>
            <p className="text-green-400 text-xs flex items-center">
              <i className="fas fa-check-circle"></i> VERIFIED USER
            </p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg mb-4 md:mb-0 hidden lg:block">
            <div className="flex items-center mb-4">
              <i className="fas fa-star text-yellow-400"></i>
              <i className="fas fa-star text-yellow-400"></i>
              <i className="fas fa-star text-yellow-400"></i>
              <i className="fas fa-star text-yellow-400"></i>
              <i className="fas fa-star text-yellow-400"></i>
            </div>
            <p className="mb-4">
              "The
              {" "} <span className="underline decoration-sky-500">value for money</span> {" "} of
              FotoExamen is very good. They are also always willing to help."
            </p>
            <p className="font-bold">María L.</p>
            <p className="text-green-400 text-xs flex items-center">
              <i className="fas fa-check-circle"></i> VERIFIED USER
            </p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg hidden lg:block">
            <div className="flex items-center mb-4">
              <i className="fas fa-star text-yellow-400"></i>
              <i className="fas fa-star text-yellow-400"></i>
              <i className="fas fa-star text-yellow-400"></i>
              <i className="fas fa-star text-yellow-400"></i>
              <i className="fas fa-star text-gray-600"></i>
            </div>
            <p className="mb-4">
              "It has helped me to
              {" "} <span className="underline decoration-sky-500">
                significantly improve my grades
              </span> {" "}
              . The outlines are the best."
            </p>
            <p className="font-bold">Antonio P.</p>
            <p className="text-green-400 text-xs flex items-center">
              <i className="fas fa-check-circle"></i> VERIFIED USER
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
