import React from 'react';
import { useSubscription } from './SubscriptionContext';
import UnlimitedButton from './UnlimitedButton';
const ModalTrialEnded = () => {
  const { subscriptionType } = useSubscription();
  return (
    <dialog id="trial_ended_modal" className="modal">
      <div className="modal-box">
        <h3 className="font-bold text-lg">Trial Credits Exhausted</h3>
        <div className="py-4">
          <p className="mb-2">Oops! It seems you've exhausted your trial credits.</p>
          <p className="mb-2">To continue enjoying PhotoExam without limits with the <span className="font-bold text-blue-600">{subscriptionType?.replaceAll("Trial", "")}</span> plan, it's time to take the next step.</p>
          <p className="">Keep taking advantage of all the benefits of PhotoExam.</p>
        </div>
        <div className="modal-action w-full">
          <UnlimitedButton />
        </div>
      </div>
    </dialog>
  );
};
export default ModalTrialEnded;