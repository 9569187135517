import React, { useEffect, useState } from "react";
import useAuth from "./useAuthToken";
const apiUrl = process.env.REACT_APP_API_URL;

export default function Prices() {
  const [activeTab, setActiveTab] = useState("monthly");
  const [email, setEmail] = useState("");
  const [token, clearToken] = useAuth();

  useEffect(() => {
    const fetchUserData = async () => {
      if (token === "") return;

      const response = await fetch(apiUrl + "/api/easyexams/checktoken", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`, // Use the token in the request
        },
      });

      // Here you make the API call to get the user data using the token
      // Assuming the API responds with data in the format { email, dateSuscriptionFinish, tripeLink }
      //const data = await fakeApiCall(token); // Replace this with your real API call
      const dataToken = await response.json();
      if (dataToken?.email) {
        localStorage.setItem("email", dataToken.email);
        setEmail(dataToken.email);
      }
    };
    fetchUserData();
  }, [token]);

  useEffect(() => {
    const storedEmail = localStorage.getItem("email");
    if (storedEmail) {
      setEmail(storedEmail);
    }

    const updatePlanPrices = (
      planCard,
      monthlyPrice,
      annualPrice,
      monthlyUrl,
      annualUrl
    ) => {
      if (activeTab === "monthly") {
        monthlyPrice?.classList.remove("hidden");
        annualPrice?.classList.add("hidden");
        planCard.href = email
          ? `${monthlyUrl}?prefilled_email=${encodeURIComponent(email)}`
          : monthlyUrl;
      } else {
        monthlyPrice?.classList.add("hidden");
        annualPrice?.classList.remove("hidden");
        planCard.href = email
          ? `${annualUrl}?prefilled_email=${encodeURIComponent(email)}`
          : annualUrl;
      }
    };

    const planCard1 = document.getElementById("plan-card1");
    const monthlyPrice1 = document.getElementById("monthly-price1");
    const annualPrice1 = document.getElementById("annual-price1");

    const planCard2 = document.getElementById("plan-card2");
    const monthlyPrice2 = document.getElementById("monthly-price2");
    const annualPrice2 = document.getElementById("annual-price2");

    const planCard3 = document.getElementById("plan-card3");
    const monthlyPrice3 = document.getElementById("monthly-price3");
    const annualPrice3 = document.getElementById("annual-price3");

    // Update prices for Plan 1
    if (planCard1 && monthlyPrice1 && annualPrice1) {
      updatePlanPrices(
        planCard1,
        monthlyPrice1,
        annualPrice1,
        "https://buy.stripe.com/5kAaFY0efepSbK0cMR",
        "https://buy.stripe.com/eVabK23qr81u15m6oE"
      );
    }

    // Update prices for Plan 2
    if (planCard2 && monthlyPrice2 && annualPrice2) {
      updatePlanPrices(
        planCard2,
        monthlyPrice2,
        annualPrice2,
        "https://buy.stripe.com/aEU4hAf991D69BS3ci",
        "https://buy.stripe.com/cN29BU9OP95y01i5kB"
      );
    }

    // Update prices for Plan 3
    if (planCard3 && monthlyPrice3 && annualPrice3) {
      updatePlanPrices(
        planCard3,
        monthlyPrice3,
        annualPrice3,
        "https://buy.stripe.com/dR65lE8KL6XqeWcfZ5",
        "https://buy.stripe.com/3cs5lE9OPchKbK05kC"
      );
    }
  }, [activeTab, email]);

  return (
    <>
      <div className="flex flex-col items-center justify-center">
        <div className="flex items-center mt-8 mb-2">
          <button
            className={`mr-2 ${
              activeTab === "monthly"
                ? "links bg-blue-600 text-white py-3 px-6 rounded-lg hover:scale-105 transform transition duration-500 hover:bg-blue-700y"
                : "btn"
            }`}
            onClick={() => setActiveTab("monthly")}
          >
            Monthly
          </button>
          <button
            className={`${
              activeTab === "annual"
                ? "links bg-blue-600 text-white py-3 px-6 rounded-lg hover:scale-105 transform transition duration-500 hover:bg-blue-700"
                : "btn"
            }`}
            onClick={() => setActiveTab("annual")}
          >
            🔥 Annual: 6+ months free
          </button>
        </div>
      </div>
      <div className="px-4 py-8 grid grid-cols-1 md:grid-cols-3 gap-4 items-stretch">
        <a
          id="plan-card1"
          className="card bg-white p-6 shadow-lg rounded-lg flex flex-col justify-between my-8 hover:shadow-xl transition-shadow duration-300 cursor-pointer"
          href="https://buy.stripe.com/5kAaFY0efepSbK0cMR"
          target="_blank"
          rel="noreferrer"
        >
          <div>
            <div id="monthly-price1" className="text-5xl font-bold">
            $9 <span className="text-sm">/ month</span>
            </div>
            <div id="annual-price1" className="text-5xl font-bold hidden">
              $4.5 <span className="text-sm">/ month </span>
            </div>
            <h2 className="text-2xl font-bold mt-4 mb-2">Basic Plan</h2>
            <ul className="my-4 text-left">
              <li>
                ✔
                <span className="underline decoration-sky-500">
                  Solve and correct 
                </span> {" "}
                exams, assignments or exercises without limit
              </li>
              <li>
                ✔
                <span className="underline decoration-sky-500">
                  Basic level AI
                </span> {" "}
              </li>
              <li>✔ Chat with AI Tutors</li>
              <li>✔ Train the AI Tutor to talk with your PDFs</li>
              <li>✔ Summarize texts</li>
              <li>✔ Highlight documents</li>
              <li>✔ Complete exam history</li>
            </ul>
          </div>
          <div className="mt-auto">
            <div className="links bg-blue-600 text-white py-3 px-6 rounded-lg hover:scale-105 transform transition duration-500 hover:bg-blue-700 text-center">
              3-day free trial ➤
            </div>
            <div className="text-xs mt-4">
              Enjoy constant improvements and our AI without limits. 3-day free trial.
            </div>
          </div>
        </a>
        <a
          id="plan-card2"
          className="card bg-white p-6 shadow-xl rounded-lg border-2 border-info flex flex-col justify-between my-8 hover:shadow-2xl transition-shadow duration-300 cursor-pointer"
          href="https://buy.stripe.com/aEU4hAf991D69BS3ci"
          target="_blank"
          rel="noreferrer"
        >
          <div>
            <div className="badge badge-info mb-4 text-white">Most popular</div>
            <div id="monthly-price2" className="text-5xl font-bold">
              $21 <span className="text-sm">/ month</span>
            </div>
            <div id="annual-price2" className="text-5xl font-bold hidden">
              $10.5 <span className="text-sm">/ month </span>
            </div>
            <h2 className="text-2xl font-bold mt-4 mb-2">Standard Plan</h2>
            <ul className="my-4 text-left">
              <li>
                ✔
                <span className="underline decoration-sky-500">
                  Solve and correct
                </span> {" "}
                exams, assignments or exercises without limit
              </li>
              <li>
                ✔
                <span className="underline decoration-sky-500">
                  Medium level AI
                </span> {" "}
                : more trained than the basic one and with better results.
              </li>
              <li>
                ✔<span className="underline decoration-sky-500">Summarize</span> {" "}
                texts
              </li>
              <li>
                ✔<span className="underline decoration-sky-500">Highlight</span> {" "}
                documents
              </li>
              <li>✔ Complete exam history</li>
              <li>
                ✔ Chat with {" "}
                <span className="underline decoration-sky-500">
                  Improved AI Tutors
                </span> {" "}
              </li>
              <li>
                ✔ Train the Improved AI Tutor to {" "}
                <span className="underline decoration-sky-500">
                  talk with your PDFs
                </span> {" "}
              </li>
              <li>✔ Faster processing speed</li>
              <li>
                ✔
                <span className="underline decoration-sky-500">
                  Conceptual schema creator
                </span> {" "}
                with AI
              </li>
              <li>
                ✔
                <span className="underline decoration-sky-500">
                  Interactive quizzes
                </span> {" "}
              </li>
            </ul>
          </div>
          <div className="mt-auto">
            <div className="links bg-blue-600 text-white py-3 px-6 rounded-lg hover:scale-105 transform transition duration-500 hover:bg-blue-700 text-center">
              3-day free trial ➤
            </div>
            <div className="text-xs mt-4">
              Enjoy constant improvements and our AI without limits. 3-day free trial.
            </div>
          </div>
        </a>

        <a
          id="plan-card3"
          className="card bg-white p-6 shadow-lg rounded-lg flex flex-col justify-between my-8 hover:shadow-xl transition-shadow duration-300 cursor-pointer"
          href="https://buy.stripe.com/dR65lE8KL6XqeWcfZ5"
          target="_blank"
          rel="noreferrer"
        >
          <div>
            <div id="monthly-price3" className="text-5xl font-bold">
              $42 <span className="text-sm">/ month</span>
            </div>
            <div id="annual-price3" className="text-5xl font-bold hidden">
              $21 <span className="text-sm">/ month</span>
            </div>
            <h2 className="text-2xl font-bold mt-4 mb-2">Pro Plan</h2>
            <ul className="my-4 text-left">
              <li>
                ✔
                <span className="underline decoration-sky-500">
                  Solve and correct
                </span> {" "}
                exams, assignments or exercises without limit
              </li>
              <li>
                ✔
                <span className="underline decoration-sky-500">
                  Advanced level AI
                </span> {" "}
                : The most advanced AI in the market with better results than
                medium and basic
              </li>
              <li>✔ Summarize texts</li>
              <li>✔ Highlight documents</li>
              <li>✔ Complete exam history</li>
              <li>✔ Chat with Improved AI Tutors</li>
              <li>✔ Train the Improved AI Tutor to talk with your PDFs</li>
              <li>✔ Faster processing speed</li>
              <li>✔ Conceptual schema creator with AI</li>
              <li>✔ Interactive quizzes</li>
              <li>✔ Improved recognition of graphs, formulas, and drawings</li>
            </ul>
          </div>
          <div className="mt-auto">
            <div className="links bg-blue-600 text-white py-3 px-6 rounded-lg hover:scale-105 transform transition duration-500 hover:bg-blue-700 text-center">
              3-day free trial ➤
            </div>
            <div className="text-xs mt-4">
              Enjoy constant improvements and our AI without limits. 3-day free trial.
            </div>
          </div>
        </a>
      </div>
    </>
  );
}
