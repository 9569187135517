import React, { useState } from 'react';
import useAuth from './useAuthToken';
const UnlimitedButton = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [token] = useAuth();
  const handleEndTrial = async () => {
    setIsLoading(true);
    setErrorMessage('');
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/easyexams/end_trial`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      const data = await response.json();
      if (response.ok) {
        // Trial ended successfully
        alert('Your trial has ended, you can now use PhotoExam without limits. Check your profile to review your subscription. Enjoy!');
        window.location.href = '/profile';
      } else if (response.status === 400) {
        // Card validation failed
        setErrorMessage(data.message || 'Card validation failed. Please check your payment information.');
      } else if (response.status === 404) {
        // Customer or trial subscription not found
        setErrorMessage(data.error || 'No active trial subscription found.');
      } else {
        // Other errors
        setErrorMessage(data.error || 'An error occurred while processing your request.');
      }
    } catch (error) {
      console.error('Error ending trial:', error);
      setErrorMessage('An unexpected error occurred. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div className='w-full flex flex-col items-center justify-center'>
      <button
        className="btn btn-primary w-full"
        onClick={handleEndTrial}
        disabled={isLoading}
      >
        {isLoading ? 'Processing...' : 'END TRIAL'}
      </button>
      {errorMessage && <p className="text-red-500 mt-2 text-center">{errorMessage}</p>}
    </div>
  );
};
export default UnlimitedButton;