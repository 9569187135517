import React, { useEffect, useRef, useState } from "react";
import GoogleAuth from "./GoogleAuth";
import useAuth from "./useAuthToken";

const APIURL = `${process.env.REACT_APP_API_URL}/api/easyexams`;
const TURNSTILE_SITE_KEY = "0x4AAAAAAAkKxkBp6yJYqY5I"; // Your site key

export default function Login() {
  const [token, clearToken] = useAuth();
  const turnstileRef = useRef(null);
  const [turnstileToken, setTurnstileToken] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (token) {
      window.location.href = "/";
    }

    const urlParams = new URLSearchParams(window.location.search);
    const clientReferenceId = urlParams.get('client_reference_id');
    if (clientReferenceId) {
      localStorage.setItem('client_reference_id', clientReferenceId);
    }

    const script = document.createElement("script");
    script.src = "https://challenges.cloudflare.com/turnstile/v0/api.js?render=explicit";
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    script.onload = () => {
      if (turnstileRef.current && !turnstileRef.current.hasChildNodes()) {
        window.turnstile.render(turnstileRef.current, {
          sitekey: TURNSTILE_SITE_KEY,
          callback: function(token) {
            console.log("Turnstile token:", token);
            setTurnstileToken(token);
            // Hide the captcha once it's successfully completed
            turnstileRef.current.style.display = "none";
          },
        });
      }
    };

    return () => {
      document.body.removeChild(script);
    };
  }, [token]);

  async function signIn(e) {
    e.preventDefault();
    if (!turnstileToken) {
      alert("Please complete the captcha.");
      return;
    }
    if (isSubmitting) return;
    setIsSubmitting(true);
    await sendDataToAPI(turnstileToken);
  }

  async function sendDataToAPI(turnstileToken) {
    const emailInput = document.getElementById("email");
    const disclaimerCheckbox = document.getElementById("disclaimer");
    const clientReferenceId = localStorage.getItem('client_reference_id');

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(emailInput.value)) {
      alert("Please provide a valid email address.");
      setIsSubmitting(false);
      return;
    }

    if (!disclaimerCheckbox.checked) {
      alert("Please accept the disclaimer to continue.");
      setIsSubmitting(false);
      return;
    }

    const bodyParams = {
      email: emailInput.value,
      'cf-turnstile-response': turnstileToken,
    };

    if (clientReferenceId) {
      bodyParams['client_reference_id'] = clientReferenceId;
    }

    try {
      const response = await fetch(APIURL + "/emailvalidate", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams(bodyParams),
      });

      if (response.status !== 200) {
        const text = await response.text();
        alert(text || "There was an error validating the email.");        
        setIsSubmitting(false);
        return;
      }

      const responseData = await response.json();
      if (responseData?.token) {
        window.location.href = `/?token=${responseData.token}`;
        return;
      }

      alert("We have sent a login link to your inbox. Please check your email and the SPAM folder if you don't see it.");
    } catch (error) {
      console.error("Error making POST request:", error);
      alert("There was an error making the request. Please try again by reloading the page.");
      setIsSubmitting(false);
    }
  }

  return (
    <div className="max-w-xl mx-auto my-12">
      <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <div className="flex items-center justify-between">
          <h1 className="text-2xl leading-6 font-medium text-gray-900 mb-2" id="modal-title">
            Login
          </h1>
        </div>
        <p className="mt-4 text-sm text-gray-500">
          Enter your email to receive an access link or use your Google account to log in quickly and securely.
        </p>
        <form onSubmit={signIn}>
          <div className="flex items-center justify-center mb-2 mt-2">
            <label htmlFor="email" className="mb-2 hidden">
              Email:
            </label>
            <input
              type="email"
              id="email"
              name="email"
              required
              className="input input-bordered w-full"
              placeholder="Email"
            />
          </div>
          <div className="flex items-center text-xs mb-4">
            <input
              type="checkbox"
              id="disclaimer"
              name="disclaimer"
              required
              className="mr-2"
            />
            <label htmlFor="disclaimer">
              I agree to receive emails and accept the{" "}
              <a
                href="terms-of-use.html"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 hover:text-blue-700 underline"
              >
                terms of service
              </a>
            </label>
          </div>
          <div ref={turnstileRef} className="my-4"></div>
          <button 
            type="submit" 
            className="btn w-full mt-4" 
            disabled={!turnstileToken || isSubmitting}
          >
            {isSubmitting ? 'Sent' : 'Enter'}
          </button>
          <hr className="my-4" />
          <GoogleAuth />
        </form>
      </div>
    </div>
  );
}
