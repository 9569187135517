import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import biologicPaladinImage from "../assets/biologic-paladin.webp";
import generalTutor from "../assets/general-tutor.webp";
import historyHunter from "../assets/history-hunter.webp";
import mathMage from "../assets/math-mage.webp";
import CurrentPath from "./CurrentPath";
import MarkdownPreview from "./MarkdownPreview";
import { useSubscription } from "./SubscriptionContext";
import useAuth from "./useAuthToken";

const controller = new AbortController();
const { signal } = controller;

const apiUrl = process.env.REACT_APP_API_URL;

const predefinedTutors = [
  {
    id: 1,
    name: "Master Tutor",
    subject: "General Tutor",
    image: generalTutor,
  },
  {
    id: 2,
    name: "Math Mage",
    subject: "Math Tutor",
    image: mathMage,
  },
  {
    id: 3,
    name: "Biologic Paladin",
    subject: "Biology Tutor",
    image: biologicPaladinImage,
  },
  {
    id: 4,
    name: "History Hunter",
    subject: "History Tutor",
    image: historyHunter,
  },
  {
    id: "custom", // Make sure this value matches what you're looking for
    name: "Custom",
    subject: "Upload a PDF with the syllabus you want",
    image: generalTutor,
    isCustom: true, // You can add this property to differentiate custom tutors
  },
];

const TutorCard = ({
  id,
  name,
  subject,
  image,
  isCustom,
  onSelect,
  isSelected,
}) => {
  const cardClasses = `transition-transform duration-300 ease-in-out ${
    isSelected ? "scale-105" : "scale-100"
  } ${
    isCustom ? "border-4 border-yellow-400 shadow-lg" : "border border-gray-200"
  } ${!isSelected && "opacity-50"}`;

  return (
    <div
      className={`p-4 m-2 cursor-pointer rounded-lg ${cardClasses}`}
      onClick={() => onSelect(id)}
    >
      <img src={image} alt={name} className="w-32 h-32 rounded-full mx-auto" />
      <h3 className="text-xl font-bold text-center mt-4">{name}</h3>
      <p className="text-center text-gray-600">{subject}</p>
    </div>
  );
};

// New component TypingMessage
const TypingMessage = ({ text }) => {
  return <div className="p-2 my-1 rounded bg-green-200">Tutor: {text}</div>;
};

const Chat = React.forwardRef(({ assistantData, selectedTutor }, ref) => {
  const token = localStorage.getItem("token");
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [currentMessage, setCurrentMessage] = useState(""); // New state for the message being constructed
  const [isFinal, setIsFinal] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const fileInputRef = useRef(null);
  const inputRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append("file", file); // Make sure the backend expects 'image' as the key

    // Here you would call the API as shown, using formData
    try {
      const response = await fetch(apiUrl + "/api/easyexams/ocr", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`, // Use the token in the request
        },
        body: formData,
      });

      if (response.ok) {
        const responseData = await response.json();
        if (responseData.nowpay) {
          // Handle Stripe modal visibility or redirection
          window.location.href = "https://www.fotoexamen.com/precios.html";
        } else {
          // Update the state with the recognized text
          setNewMessage(responseData.texts); // You can also update the new message to send it directly
        }
      } else {
        alert(
          "Text could not be detected in the image. Ensure your image is smaller than 20 MB and in one of the following formats: .png, .jpeg, .gif, or .webp."
        );
      }
    } catch (error) {
      console.error("Error processing the image:", error);
    }
  };

  const fetchData = async () => {
    if (!assistantData || !token || isFetching) return;
    setIsFetching(true);
    let promptutor;
    if (assistantData.tutor_name === "Math Mage") {
      promptutor =
        "You are a Math tutor, ask the student what they need.";
    } else if (assistantData.tutor_name === "History Hunter") {
      promptutor =
        "You are a History tutor, ask the student what they need.";
    } else if (assistantData.tutor_name === "Biologic Paladin") {
      promptutor =
        "You are a Biology tutor, ask the student what they need.";
    } else {
      promptutor = "You are a general tutor, ask the student what they need.";
    }

    await fetchEventSource(
      `${process.env.REACT_APP_API_URL}/api/easyexams/tutors/stream/${assistantData.assistant_id}/${assistantData.thread_id}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "text/event-stream",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          prompt: promptutor,
        }),
        onopen(res) {
          if (res.ok) console.log("Connection made", res);
          else console.error("Connection failed", res);
        },
        onmessage(event) {
          const { text_value, final } = JSON.parse(event.data);
          setCurrentMessage((prev) => prev + text_value);
          if (final) {
            setMessages((prevMessages) => [
              ...prevMessages,
              { text: currentMessage + text_value, sender: "tutor" },
            ]);
            setCurrentMessage("");
          }
        },
        onclose() {},
        onerror(err) {
          console.error("There was an error from the server", err);
        },
        signal,
      }
    );

    setIsFetching(false);
  };

  useImperativeHandle(ref, () => ({
    fetchData,
  }));

  useEffect(() => {
    if (isFinal) {
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: currentMessage, sender: "tutor" },
      ]);
      setCurrentMessage(""); // Clear the message being constructed for the next message
      setIsFinal(false); // Reset isFinal for the next message
    }
  }, [isFinal, currentMessage]);

  const sendMessage = async (messageText) => {
    if (!messageText.trim()) return;
    // Clear the input field
    setIsLoading(true);
    setNewMessage("");
    // Add the user's message to the messages state
    setMessages((prevMessages) => [
      ...prevMessages,
      { text: messageText, sender: "user" },
    ]);

    await fetchEventSource(
      `${process.env.REACT_APP_API_URL}/api/easyexams/tutors/stream/${assistantData.assistant_id}/${assistantData.thread_id}`,
      {
        method: "POST", // Ensure the method is correct for your API
        headers: {
          Authorization: `Bearer ${token}`, // Ensure to send the token if necessary
          Accept: "text/event-stream",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          // First prompt
          prompt: messageText,
        }),
        onopen(res) {
          if (res.ok) {
          } else {
            console.error("Connection failed", res);
          }
        },
        onmessage(event) {
          const { text_value, final } = JSON.parse(event.data);

          // Check if the message is final
          if (final) {
            // If final, add the complete message and reset the partial message
            setMessages((prevMessages) => [
              ...prevMessages,
              { text: currentMessage + text_value, sender: "tutor" },
            ]);
            setCurrentMessage("");
          } else {
            // If not final, just update the partial message
            setCurrentMessage((prev) => prev + text_value);
          }
        },
        onclose() {
          controller.abort();
        },
        onerror(err) {
          console.error("There was an error from the server", err);
        },
        signal,
      }
    );
    setIsLoading(false);
  };

  const handleSendMessage = () => {
    sendMessage(newMessage);
  };

  // Component to display the typing message
  const TypingMessage = ({ text }) => (
    <div className="p-2 my-1 rounded">
      <div className="chat-bubble">
      <MarkdownPreview content={text}/>
        <div className="text-xs">Typing...</div>
      </div>
    </div>
  );

  const handleOpenFileDialog = () => {
    fileInputRef.current.click();
  };

  return (
    <div className="w-full mt-4 p-4 bg-white shadow rounded-lg">
      <div
        className="mb-4 p-2 bg-gray-800 rounded h-[32rem] overflow-auto"
        style={{ minHeight: "16rem" }}
      >
        {messages.length === 0 ? (
          <div className="text-gray-400 text-center">Calling the tutor...</div>
        ) : (
          <div className="overflow-auto h-full" ref={messagesEndRef}>
            {messages.map((msg, index) => (
              <div
                key={index}
                className={`chat ${
                  msg.sender === "user" ? "chat-end" : "chat-start"
                }`}
              >
                <div className="chat-image avatar">
                  <div className="w-10 rounded-full">
                    {msg.sender !== "user" ? (
                      <img
                        alt="Avatar"
                        src={
                          selectedTutor &&
                          predefinedTutors.find((t) => t.id === selectedTutor)
                            .image
                        }
                      />
                    ) : (
                      <div className="avatar placeholder">
                        <div className="bg-neutral text-neutral-content rounded-full w-12 pr-2">
                          <span>🙋🏻‍♂️</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="chat-header text-white">
                  {selectedTutor && msg.sender !== "user"
                    ? predefinedTutors.find((t) => t.id === selectedTutor).name
                    : "You"}{" "}
                  <time className="text-xs opacity-50">
                    {new Date().toLocaleTimeString().slice(0, 5)}
                  </time>
                </div>
                <div className="chat-bubble">
                  <MarkdownPreview content={msg.text}/>
                </div>
                <div className="chat-footer opacity-50 text-white">
                  {msg.sender === "user" ? "Sent" : "Seen"}
                </div>
              </div>
            ))}
            {currentMessage && <TypingMessage text={currentMessage} />}
          </div>
        )}
      </div>
      <div className="flex">
        <input
          type="file"
          id="fileInput"
          accept="image/*"
          onChange={handleImageChange}
          style={{ display: "none" }}
          ref={fileInputRef}
        />
        <button
          type="button"
          className="p-2"
          onClick={handleOpenFileDialog}
          hidden={isLoading || messages.length === 0}
        >
          <FontAwesomeIcon icon={faCamera} className="w-6 h-6" />
        </button>
        <textarea // Change this to 'textarea' to allow multiple lines
          ref={inputRef} // Assign the reference here
          className="border rounded p-2 flex-grow h-16"
          placeholder="Type your message here..."
          value={newMessage}
          onChange={(e) => {
            setNewMessage(e.target.value);
          }}
          onKeyPress={(e) =>
            e.key === "Enter" && !e.shiftKey && handleSendMessage()
          } // Add '!e.shiftKey' to allow line breaks with Shift+Enter
          style={{ overflow: "hidden" }} // Prevent scrollbar
          hidden={isLoading || messages.length === 0}
        />
        {isLoading && (
          <div className="flex items-center space-x-2">
            <div className="p-2 my-1 rounded bg-green-100">
              Loading response...{" "}
              {/* Replace this with your own loading animation */}
            </div>
            <div className="loading"></div>
          </div>
        )}
        <button
          className="bg-[#66cc8a] hover:bg-[#58b177] font-bold py-2 px-4 rounded ml-2"
          onClick={handleSendMessage}
          hidden={isLoading || messages.length === 0}
        >
          Send
        </button>
      </div>
    </div>
  );
});

const TutorsGallery = ({setShowNoTokenModal}) => {
  const [selectedTutor, setSelectedTutor] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const [assistantData, setAssistantData] = useState(null);
  const chatRef = useRef();
  const fileInputRef = useRef(null); // Reference to the file input
  const [file, setFile] = useState(null); // State to store the file
  const [errorMessage, setErrorMessage] = useState(null);
  const { subscriptionType, loading, error, refreshSubscription, remainingCredits } = useSubscription();
  const [token, clearToken] = useAuth();

  const [loadingIniciarChat, setLoadingIniciarChat] = useState(false);

  const handleSelectTutor = (id) => {
    setSelectedTutor(id);
    setShowForm(true);
    setShowChat(false);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0]; // The first file from the input
    if (!file) {
      console.error("No file selected");
      return;
    }
    setFile(file); // Store the file in the state
  };

  const createAssistant = async (tutorName, file) => {
    if (!token) {
      console.error("No authorization token found");
      return;
    }

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    let body = null;

    if (tutorName === "Custom") {
      // If the tutor is custom, the file will be sent
      const formData = new FormData();
      formData.append("file", file);
      formData.append("tutor_name", tutorName);
      for (let pair of formData.entries()) {
        console.log(pair[0] + ": " + pair[1]); // Check if the file and other data are added correctly
      }
      body = formData;
    } else {
      // For other tutors, the file is not sent
      const formData = new FormData();
      formData.append("tutor_name", tutorName);
      body = formData;
    }

    try {
      const response = await fetch(`${apiUrl}/api/easyexams/tutors`, {
        method: "POST",
        headers: headers,
        body: body,
      });

      if (!response.ok) {
        console.error("Error creating assistant:", response.statusText);
        return;
      }

      const data = await response.json();
      setAssistantData({
        thread_id: data.thread_id,
        tutor_name: tutorName,
        assistant_id: data.assistant_id,
      });
    } catch (error) {
      console.error("Error creating assistant:", error);
    }
  };

  const handleStartConversation = () => {
    if (!token) {
      setShowNoTokenModal(true);
      return;
    }
    
    if (subscriptionType === "No Subscription") {
      alert("You need to subscribe to the service to start the conversation.");
      return;
    }

    if (subscriptionType.includes("Trial") && remainingCredits === 0){
      document.getElementById('trial_ended_modal').showModal();
      return;
    }

    if (!selectedTutor) {
      return;
    }
    // Find the selected tutor in your predefined tutors list
    const tutor = predefinedTutors.find((t) => t.id === selectedTutor);

    if (!tutor) {
      setErrorMessage("You must select a tutor.");
      return;
    }

    if (tutor.name === "Custom") {
      if (!file) {
        setErrorMessage("You must upload a PDF to start the conversation.");
        return;
      }
    }

    setLoadingIniciarChat(true);
    // Call createAssistant with the selected tutor's name
    createAssistant(tutor.name, file)
      .then(() => {
        // chatRef.current.fetchData();
        setShowChat(true); // Ensure to show the Chat component after creating the assistant
      })
      .finally(() => {
        setLoadingIniciarChat(false);
        refreshSubscription();
      });
  };

  useEffect(() => {
    if (showChat && chatRef.current) {
      chatRef.current.fetchData();
    }
  }, [showChat, chatRef.current]);

  const handleReturnToSelection = () => {
    setShowChat(false);
    setShowForm(false);
    setSelectedTutor(null);
  };

  const SEOText = () => (
    <div className="mt-8 p-6 bg-gray-100 rounded-lg shadow-md">
      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">Personalized Virtual Assistants for Students</h2>
        <p className="mb-4 text-gray-600">
          Discover a new way of learning with our AI-powered virtual tutors. Upload your syllabus PDF and get instant answers to all your questions. Our e-learning platform revolutionizes personalized study.
        </p>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          <li className="mb-2">Online tutoring available 24/7</li>
          <li className="mb-2">Adaptive learning based on AI</li>
          <li className="mb-2">Personalized academic support for each student</li>
        </ul>
      </section>
  
      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">Interact with Specialized AI Tutors</h2>
        <p className="mb-4 text-gray-600">
          Choose from a variety of AI tutors, each specialized in different academic areas. Our online education system lets you select the virtual assistant that best suits your learning needs.
        </p>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          <li className="mb-2">Wide range of AI tutors for different subjects</li>
          <li className="mb-2">Real-time personalized academic assistance</li>
          <li className="mb-2">Interactive and dynamic learning platform</li>
        </ul>
      </section>
  
      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">Learning Based on Your Own Material</h2>
        <p className="mb-4 text-gray-600">
          Make the most of your study resources. Upload your syllabus PDF and let our AI tutor analyze it. This innovative e-learning tool allows you to ask specific questions about your study material.
        </p>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          <li className="mb-2">Upload PDFs with personalized syllabi</li>
          <li className="mb-2">Intelligent analysis of academic content</li>
          <li className="mb-2">Questions and answers about your specific material</li>
        </ul>
      </section>
  
      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">Benefits of Learning with AI</h2>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          <li className="mb-2">Improved understanding of complex concepts</li>
          <li className="mb-2">Increased information retention</li>
          <li className="mb-2">Flexibility in study schedules</li>
          <li className="mb-2">Adaptation to different learning styles</li>
          <li className="mb-2">Efficient preparation for exams and assessments</li>
        </ul>
      </section>
  
      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">Features of Our Virtual Tutors</h2>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          <li className="mb-2">Instant answers to your academic questions</li>
          <li className="mb-2">Step-by-step explanations of complex problems</li>
          <li className="mb-2">Suggestions for additional resources</li>
          <li className="mb-2">Tracking learning progress</li>
          <li className="mb-2">Continuous adaptation to your knowledge level</li>
        </ul>
      </section>
  
      <section>
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">Transform Your Learning Experience</h2>
        <p className="text-gray-600">
          Our AI tutors represent the future of online education. Whether you're preparing for an exam, working on a complex project, or simply looking to expand your knowledge, our virtual tutoring system is designed to help you achieve your academic goals. Experience the power of personalized and adaptive learning with our AI-based virtual assistants. Start your journey to academic success today!
        </p>
      </section>
    </div>
  );

  return (
    <div className="container mx-auto p-5 rounded-md shadow-lg mb-20 mt-20">
      <CurrentPath text="AI Tutors" />
      {!showChat && (
        <>
          <div className="mb-8 text-center max-w-xl mx-auto py-12">
            <h1 className="text-4xl font-bold mb-6 border-b-4 border-white pb-2">
              🤖 Chat with AI Tutors
            </h1>
            <p>
              Select an AI tutor to answer all your questions. Choose one of the predefined tutors that suits your needs (more coming soon!).
            </p>
          </div>
          <div className="flex flex-wrap mb-64 items-center justify-center">
            {predefinedTutors.map((tutor) => (
              <TutorCard
                key={tutor.id}
                {...tutor}
                onSelect={handleSelectTutor}
                isSelected={selectedTutor === tutor.id}
              />
            ))}
            {showForm && (
              <div className="w-full p-4">
                {selectedTutor === "custom" && (
                  <div>
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      Upload a PDF with the syllabus you want and ask questions (only PDFs up to 100MB)
                    </label>
                    <input
                      type="file"
                      accept=".pdf"
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      onChange={handleFileChange}
                    />
                  </div>
                )}
                {errorMessage && (
                  <div className="text-red-500 text-sm font-bold mb-2">
                    {errorMessage}
                  </div>
                )}
                <div className="flex items-center justify-center space-x-4">
                  <button
                    className="mt-4 btn btn-primary font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    onClick={handleStartConversation}
                  >
                    Start conversation
                    {loadingIniciarChat && (
                      <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-white-600"></div>
                    )}
                  </button>
                </div>
              </div>
            )}
            {!token && <SEOText />}
          </div>
        </>
      )}

      {showChat && (
        <>
          <button
            onClick={handleReturnToSelection}
            className="btn btn-primary mb-4"
          >
            Return to select tutor
          </button>
          <Chat
            assistantData={assistantData}
            ref={chatRef}
            selectedTutor={selectedTutor}
          />
        </>
      )}
    </div>
  );
};

export { Chat, TutorCard, TutorsGallery };
