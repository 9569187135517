import { faMicrophone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { FaFilePdf } from "react-icons/fa";
import { io } from "socket.io-client";
import CurrentPath from "./CurrentPath.js";
import { downloadPDF } from "./ExamList.js";
import MarkdownPreview from "./MarkdownPreview.js";
import { useSubscription } from "./SubscriptionContext.js";
import useAuth from "./useAuthToken.js";

const apiUrl = process.env.REACT_APP_API_URL;

const NoteTaker = ({ setShowNoTokenModal }) => {
  const [audio, setAudio] = useState(null);
  const [transcription, setTranscription] = useState("");
  const [notes, setNotes] = useState("");
  const [isTranscribing, setIsTranscribing] = useState(false);
  const [isGeneratingNotes, setIsGeneratingNotes] = useState(false);
  const [notesData, setNotesData] = useState(null);
  const { subscriptionType, loading, error, refreshSubscription, remainingCredits } = useSubscription();
  const [token, clearToken] = useAuth();

  const socketRef = useRef(null);

  useEffect(() => {
    setNotesData({
      transcription: transcription,
      notes: notes,
      lastTranscription: new Date(),
    });
  }, [transcription, notes]);

  const connectSocket = () => {
    const socket = io(apiUrl, {
      reconnectionAttempts: 5,
      reconnectionDelay: 100,
      maxHttpBufferSize: 200 * 1024 * 1024,
      reconnectionDelayMax: 100,
    });

    socket.on("connect", () => {
      console.log("Socket connected.");
    });

    socket.on("disconnect", () => {
      console.log("Socket disconnected.");
    });

    return socket;
  };

  const handleAudioChange = (e) => {
    if (!token) {
      setShowNoTokenModal(true);
      return;
    }

    if (!subscriptionType.includes("Pro")) {
      alert("You need a premium pro subscription to use Note Taking.");
      return;
    }

    if (subscriptionType.includes("Trial") && remainingCredits === 0){
      document.getElementById('trial_ended_modal').showModal();
      return;
    }

    const file = e.target.files[0];
    if (file && file.type.startsWith("audio/")) {
      setAudio(file);
    } else {
      alert("Please upload a valid audio file.");
    }
  };

  const transcribeAudio = async () => {
    setIsTranscribing(true);
    if (!token) {
      setShowNoTokenModal(true);
      setIsTranscribing(false);
      return;
    }

    socketRef.current = connectSocket();

    refreshSubscription();

    socketRef.current.on("transcribe_response", (data) => {
      setTranscription((prev) => prev + "\n" + data.text); // Adding newline for better formatting
    });

    socketRef.current.on("transcribe_end", () => {
      setIsTranscribing(false);
      socketRef.current.disconnect();
    });

    const reader = new FileReader();
    reader.onload = (e) => {
      const base64Data = e.target.result.split(",")[1];
      socketRef.current.emit("transcribe", {
        token: token,
        files: { audio: base64Data },
      });
    };
    reader.readAsDataURL(audio);
  };

  const generateNotes = async () => {
    setIsGeneratingNotes(true);
    if (!token) {
      setIsGeneratingNotes(false);
      return;
    }

    socketRef.current = connectSocket();

    socketRef.current.on("notes_response", (data) => {
      setNotes((prev) => prev + data.notes);
    });

    socketRef.current.on("notes_end", () => {
      setIsGeneratingNotes(false);
      socketRef.current.disconnect();
    });

    socketRef.current.emit("generate_notes", {
      token: token,
      transcription: transcription,
    });
  };
  const SEOText = () => (
    <div className="mt-8 p-6 bg-gray-100 rounded-lg shadow-md">
      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">Take Notes with AI</h2>
        <p className="mb-4 text-gray-600">
          Revolutionize your note-taking with our advanced artificial intelligence. Upload an audio file of your class, let our AI transcribe it, and automatically generate concise and clear notes. This tool is perfect for students and professionals who need to maximize their efficiency and ensure the accuracy of their notes.
        </p>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          <li className="mb-2">Upload audio files of your classes</li>
          <li className="mb-2">Accurate automatic transcription of the audio</li>
          <li className="mb-2">Generation of concise and well-structured notes</li>
          <li className="mb-2">Ideal for students and professionals</li>
        </ul>
      </section>
  
      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">How It Works</h2>
        <p className="mb-4 text-gray-600">
          Our AI note-taking system is easy to use and highly efficient. Upload your audio file to our platform, and the AI will accurately transcribe the content. Then, use our tool to generate structured notes that will help you study and review the material effectively.
        </p>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          <li className="mb-2">Upload the audio file of your class</li>
          <li className="mb-2">The AI transcribes the content automatically</li>
          <li className="mb-2">Generate well-organized and clear notes</li>
        </ul>
      </section>
  
      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">Advantages of AI Note-Taking</h2>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          <li className="mb-2">Time-saving in note-taking</li>
          <li className="mb-2">Accuracy and clarity in the generated notes</li>
          <li className="mb-2">Ease of reviewing and studying the material</li>
          <li className="mb-2">Reduction of manual effort in transcription</li>
          <li className="mb-2">Access to well-structured notes at any time</li>
        </ul>
      </section>
  
      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">Applications of AI Note-Taking</h2>
        <p className="mb-4 text-gray-600">
          Our AI note-taking tool is versatile and useful in a variety of academic and professional contexts. Whether you are in university, at a conference, or in an important meeting, this tool will allow you to obtain precise and organized notes effortlessly.
        </p>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          <li className="mb-2">Students in classes and conferences</li>
          <li className="mb-2">Professionals in meetings and presentations</li>
          <li className="mb-2">Researchers and academics in seminars</li>
          <li className="mb-2">Anyone who needs precise and well-structured notes</li>
        </ul>
      </section>
  
      <section>
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">Improve Your Note-Taking with AI</h2>
        <p className="text-gray-600">
          Our AI note-taking tool is designed to transform the way you capture and organize information. Upload your audio file, let the AI transcribe it, and generate concise and clear notes for you. Whether you are studying for an exam, attending a conference, or participating in a meeting, this tool will help you get perfect notes quickly and efficiently. Start using our AI note-taking tool today and take your study and work to the next level!
        </p>
      </section>
    </div>
  );

  return (
    <div className="container mx-auto p-5 rounded-md shadow-lg mb-20 mt-20">
      <CurrentPath text="Note Taking" />
      <div className="container max-w-4xl mx-auto p-8 bg-white rounded">
        <div className="mb-8 text-center max-w-xl mx-auto">
          <h1 className="text-4xl font-bold mb-6 border-b-4 border-white pb-2">
            📝 Note Taking
          </h1>
          <p>
            Upload an audio file of your class, transcribe it, and generate
            concise notes using our advanced artificial intelligence.
          </p>
        </div>

        <div className="mb-8 space-y-6">
          <h2 className="text-2xl font-bold mb-4">Upload your audio file:</h2>
          <div className="flex justify-center items-center">
            <input
              type="file"
              accept="audio/*"
              className="file-input w-full max-w-xs"
              onChange={handleAudioChange}
            />
          </div>
        </div>

        {audio && (
          <div className="mb-8">
            <audio controls className="w-full">
              <source src={URL.createObjectURL(audio)} type={audio.type} />
              Your browser does not support the audio element.
            </audio>
          </div>
        )}

        <div className="mb-8">
          <button
            className={`btn btn-primary flex my-4 justify-center items-center bg-blue-200 hover:bg-blue-300 text-blue-700 font-semibold py-3 px-6 rounded transition ease-in-out duration-300 ${
              !audio || isTranscribing ? "opacity-50 cursor-not-allowed" : ""
            }`}
            onClick={transcribeAudio}
            disabled={!audio || isTranscribing}
          >
            <FontAwesomeIcon icon={faMicrophone} className="mr-2" />
            Transcribe Audio
          </button>
        </div>

        {isTranscribing && (
          <div className="flex flex-col justify-center items-center mb-4">
            <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-600 my-4"></div>
            <p className="text-lg font-semibold">Transcribing audio...</p>
          </div>
        )}

        {transcription && (
          <div className="mb-8">
            <h2 className="text-2xl font-bold mb-4">Transcription:</h2>
            <div className="border-2 border-gray-300 p-4 rounded-lg whitespace-pre-line">
              {transcription}
            </div>
          </div>
        )}

        {transcription && (
          <div className="mb-8">
            <button
              className={`btn btn-primary flex my-4 justify-center items-center bg-green-200 hover:bg-green-300 text-green-700 font-semibold py-3 px-6 rounded transition ease-in-out duration-300 ${
                isGeneratingNotes || isTranscribing
                  ? "opacity-50 cursor-not-allowed"
                  : ""
              }`}
              onClick={generateNotes}
              disabled={isGeneratingNotes || isTranscribing}
            >
              Generate Notes
            </button>
          </div>
        )}

        {isGeneratingNotes && (
          <div className="flex flex-col justify-center items-center mb-4">
            <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-600 my-4"></div>
            <p className="text-lg font-semibold">Generating notes...</p>
          </div>
        )}

        {notes && (
          <div className="mt-8">
            <h2 className="text-2xl font-bold mb-4">Generated Notes:</h2>
            <button
              onClick={() => downloadPDF(notesData)}
              className="mb-2 bg-red-200 hover:bg-red-300 text-red-800 font-semibold py-2 px-4 rounded transition ease-in-out duration-300 flex items-center justify-center mt-4"
            >
              <FaFilePdf size={24} className="mr-2" /> Download PDF
            </button>
            <div className="border-2 border-gray-300 p-4 rounded-lg whitespace-pre-line">
              <MarkdownPreview content={notes}/>
            </div>
          </div>
        )}
      </div>
      {!token && <SEOText />}
    </div>
  );
};

export default NoteTaker;
