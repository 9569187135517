import * as pdfjsLib from "pdfjs-dist/webpack";
import { default as React, useState } from "react";
import CurrentPath from "./CurrentPath";
import Quiz from "./Quiz";
import { useSubscription } from "./SubscriptionContext";
import useAuth from "./useAuthToken";
const apiUrl = process.env.REACT_APP_API_URL;

const QuizFile = ({ setShowNoTokenModal }) => {
  const [file, setFile] = useState(null);
  const [text, setText] = useState("");
  const [quiz, setQuiz] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [token, clearToken] = useAuth();

  const { subscriptionType, loading, error, refreshSubscription, remainingCredits } = useSubscription();

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    setText(""); // Clear the text to hide the text area
    setQuiz("");
  };

  const handleTextChange = (event) => {
    setText(event.target.value);
    setFile(null); // Clear the file to hide the file input
    setQuiz("");
  };

  const handleGenerateQuiz = async () => {
    if (!file && !text.trim()) {
      alert("Please upload a file or write some text before generating a quiz.");
      return;
    }

    if (!token) {
      setShowNoTokenModal(true);
      console.error("No authentication token found.");
      return;
    }

    if (subscriptionType === "No Subscription") {
      alert("You need to subscribe to the service to use FotoExamen.");
      return;
    }
    
    if (subscriptionType.includes("Trial") && remainingCredits === 0){
      document.getElementById('trial_ended_modal').showModal();
      return;
    }

    if (!subscriptionType.includes("Standard") && !subscriptionType.includes("Pro")) {
      alert(
        "You need a Pro or Standard subscription to access the quizzes."
      );
      return;
    }

    setIsLoading(true);
    setErrorMessage("");

    const formData = new FormData();
    let pdftext = "";
    if (file) {
      if (file.type === "application/pdf") {
        try {
          const pdf = await pdfjsLib.getDocument(URL.createObjectURL(file))
            .promise;
          let textContent = "";
          for (let i = 1; i <= pdf.numPages; i++) {
            const page = await pdf.getPage(i);
            const text = await page.getTextContent();
            text.items.forEach((item) => {
              textContent += item.str + " ";
            });
          }
          pdftext = textContent;
          formData.append("text", pdftext);
        } catch (error) {
          console.error("Error extracting text from PDF:", error);
          setErrorMessage(
            "Error extracting text from PDF. Please try another file."
          );
          setIsLoading(false);
          return;
        }
      } else if (file.type.startsWith("image/")) {
        formData.append("files", file);
      } else {
        alert("Unsupported file type. Upload an image or PDF file.");
        setIsLoading(false);
        return;
      }
    } else {
      formData.append("text", text);
    }

    try {
      const response = await fetch(`${apiUrl}/api/easyexams/quiz`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });
      const data = await response.json();

      if (data?.too_long) {
        setErrorMessage(
          "The text is too long. Please upload another smaller file or enter less text. You have uploaded: " +
            data?.total_tokens +
            " tokens, and the maximum is " +
            data?.max_tokens +
            " tokens."
        );
        return;
      }

      if (!response.ok) {
        throw new Error(data.message || "Error fetching the quiz.");
      }

      setQuiz(JSON.parse(data.quiz));
      refreshSubscription();
    } catch (error) {
      console.error("Error generating quiz:", error);
      setErrorMessage(
        "Error. Please try again or with a smaller file. If the problem persists, contact us."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const SEOText = () => (
    <div className="mt-8 p-6 bg-gray-100 rounded-lg shadow-md">
      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">Smart Quiz Generator</h2>
        <p className="mb-4 text-gray-600">
          Revolutionize your study method with our automated test generator. Create personalized exams from your own study materials, whether they are PDF documents, images, or text. Evaluate your understanding of the topic efficiently and effectively.
        </p>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          <li className="mb-2">Automatic question creation based on your content</li>
          <li className="mb-2">Tests tailored to your specific study material</li>
          <li className="mb-2">Personalized knowledge assessment</li>
        </ul>
      </section>
  
      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">Versatile Data Input</h2>
        <p className="mb-4 text-gray-600">
          Our quiz generation system accepts multiple input formats, adapting to your needs and study preferences:
        </p>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          <li className="mb-2">Upload PDF documents with your notes or textbooks</li>
          <li className="mb-2">Upload images of handwritten notes or diagrams</li>
          <li className="mb-2">Direct text entry to create questions instantly</li>
        </ul>
      </section>
  
      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">Benefits of Automated Self-Assessment</h2>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          <li className="mb-2">Quick identification of areas needing more study</li>
          <li className="mb-2">Reinforcement of key concepts through targeted questions</li>
          <li className="mb-2">Simulation of exam conditions for better preparation</li>
          <li className="mb-2">Time-saving in manual quiz creation</li>
          <li className="mb-2">Adaptation of difficulty level according to your progress</li>
        </ul>
      </section>
  
      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">Advanced Features of Our Test Generator</h2>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          <li className="mb-2">Content analysis using artificial intelligence</li>
          <li className="mb-2">Generation of multiple-choice, true/false, and short-answer questions</li>
          <li className="mb-2">Customization of the number and type of questions</li>
          <li className="mb-2">Instant feedback on correct and incorrect answers</li>
          <li className="mb-2">Tracking progress and areas for improvement</li>
        </ul>
      </section>
  
      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">Practical Applications</h2>
        <p className="mb-4 text-gray-600">
          Our quiz generator is ideal for various learning situations:
        </p>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          <li className="mb-2">Preparation for academic exams</li>
          <li className="mb-2">Review of study material</li>
          <li className="mb-2">Continuous assessment in online courses</li>
          <li className="mb-2">Self-assessment for professionals in continuous training</li>
          <li className="mb-2">Creating interactive activities for educators</li>
        </ul>
      </section>
  
      <section>
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">Enhance Your Learning with Personalized Quizzes</h2>
        <p className="text-gray-600">
          Our Smart Quiz Generator transforms the way you prepare for exams and evaluate your knowledge. By creating tests based on your own study material, you ensure focused and efficient preparation. Whether you are studying for an important exam, reviewing for a professional certification, or simply reinforcing your understanding of a topic, our automatic question generation tool will help you achieve your learning goals. Start creating your personalized quizzes today and take your study to the next level!
        </p>
      </section>
    </div>
  );

  return (
    <div className="container mx-auto p-5 rounded-md shadow-lg mb-20 mt-20">
      <CurrentPath text={"Quiz Generator"} />
      <div className="container max-w-4xl mx-auto p-8 rounded">
        <h1 className="text-4xl font-bold border-b-4 border-white pb-2 text-center">
          📄 Quiz Generator
        </h1>
        <p className="mb-6">
          Upload a PDF document or multiple images, or you can enter text
          directly to generate a quiz based on the provided notes.
        </p>
        <h2 className="text-2xl font-bold mb-6 border-b-4 border-white pb-2 text-center">
          Write a text or upload a PDF
        </h2>
        <div>
          {!file && (
            <textarea
              className="textarea textarea-bordered h-24 w-full"
              placeholder="Write your text here..."
              value={text}
              onChange={handleTextChange}
            ></textarea>
          )}

          {!text && (
            <input
              type="file"
              onChange={handleFileChange}
              className="input input-bordered w-full mt-4"
            />
          )}
        </div>

        <button onClick={handleGenerateQuiz} className="btn btn-primary mt-4">
          Generate Quiz
        </button>

        {isLoading && (
          <div className="flex flex-col justify-center items-center">
            <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-green-600 my-4"></div>
            <p className="text-lg font-semibold">Processing...</p>
          </div>
        )}

        {errorMessage && (
          <div className="shadow-lg mt-4 p-4 rounded">
            <div>
              <span>{errorMessage}</span>
            </div>
          </div>
        )}

        {quiz && (
          <div className="border-2 border-gray-300 p-4 rounded-lg whitespace-pre-line mt-8">
            <Quiz questions={quiz} />
          </div>
        )}
      </div>
      {!token && <SEOText />}
    </div>
  );
};

export default QuizFile;
