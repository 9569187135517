import { select } from "d3-selection";
import { Transformer } from "markmap-lib";
import { Toolbar } from "markmap-toolbar";
import "markmap-toolbar/dist/style.css";
import { Markmap } from "markmap-view";
import * as pdfjsLib from "pdfjs-dist/webpack";
import React, { useEffect, useRef, useState } from "react";
import io from "socket.io-client";
import CurrentPath from "./CurrentPath";
import { useSubscription } from "./SubscriptionContext";
import useAuth from "./useAuthToken";
const apiUrl = process.env.REACT_APP_API_URL;

const MarkmapSchemaCreator = ({ setShowNoTokenModal }) => {
  const [files, setFiles] = useState([]);
  const [markmapData, setMarkmapData] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const transformer = new Transformer();
  const messageCount = useRef(0);
  const { subscriptionType, loading, error, refreshSubscription, remainingCredits } = useSubscription();
  const [token, clearToken] = useAuth();

  const markmapRef = useRef(null);
  const svgRef = useRef(null);
  const toolbarRef = useRef(null);
  const socketRef = useRef(null);

  const connectSocket = (onHtmlResponse, onGenerationComplete) => {
    const socket = io(apiUrl, {
      reconnectionAttempts: 5,
      reconnectionDelay: 100,
      maxHttpBufferSize: 200 * 1024 * 1024,
      reconnectionDelayMax: 100,
    });

    socket.on("connect", () => {
      console.log("Socket connected.");
    });

    socket.on("disconnect", () => {
      console.log("Socket disconnected.");
    });

    socket.on("markmapmsg", (data) => {
      onHtmlResponse(data);
    });

    socket.on("markmapend", (data) => {
      onGenerationComplete(data);
      socket.disconnect();
    });

    return socket;
  };

  useEffect(() => {
    if (markmapData && svgRef.current) {
      try {
        svgRef.current.innerHTML = "";
        const svg = select(svgRef.current)
          .attr("width", "100%")
          .attr("height", "100%")
          .attr(
            "viewBox",
            `0 0 ${svgRef.current.clientWidth} ${svgRef.current.clientHeight}`
          )
          .style("background-color", "white");

        const { root } = transformer.transform(
          markmapData.replace(/^```(markmap)?\n?|\n```$/gm, "").trim()
        );
        const mm = Markmap.create(svg.node(), { fit: true }, root);

        // Center the content
        mm.fit();

        if (toolbarRef.current) {
          toolbarRef.current.innerHTML = "";
          const toolbar = new Toolbar();
          toolbar.attach(mm);
          toolbar.setItems([...Toolbar.defaultItems]);
          toolbarRef.current.append(toolbar.render());
        }
      } catch (error) {
        console.error("Error processing Markmap data:", error);
        setErrorMessage(
          "Error processing the concept map. Please try again."
        );
      }
    }
  }, [markmapData]);

  const handleFilesChange = (e) => {
    setFiles([...e.target.files]);
  };

  const waitForSocketConnection = (socket) => {
    return new Promise((resolve, reject) => {
      const maxAttempts = 5;
      let attempts = 0;

      const checkSocketConnection = () => {
        if (socket.connected) {
          resolve();
        } else {
          attempts += 1;
          if (attempts >= maxAttempts) {
            reject(new Error("Socket connection failed"));
          } else {
            setTimeout(checkSocketConnection, 200); // Retry after 200ms
          }
        }
      };

      checkSocketConnection();
    });
  };

  const handleSubmit = async () => {
    if (files.length === 0) {
      alert("Please select at least one file.");
      return;
    }

    if (!token) {
      setShowNoTokenModal(true);
      console.error("No authentication token found.");
      setIsLoading(false);
      return;
    }

    if (subscriptionType === "No Subscription") {
      alert("You need to subscribe to use PhotoExam.");
      return;
    }

    if (subscriptionType.includes("Trial") && remainingCredits === 0){
      document.getElementById('trial_ended_modal').showModal();
      return;
    }

    if (!subscriptionType.includes("Standard") && !subscriptionType.includes("Pro")) {
      alert(
        "You need a Pro or Standard subscription to access concept maps."
      );
      return;
    }

    setIsLoading(true);
    setErrorMessage("");

    socketRef.current = connectSocket(
      (data) => {
        messageCount.current += 1;
        if (messageCount.current % 100 === 0) {
          setMarkmapData(data?.markmapmsg);
        }
      },
      (data) => {
        setMarkmapData(data?.markmapend);
        setIsLoading(false);
      }
    );

    try {
      await waitForSocketConnection(socketRef.current);
      refreshSubscription();
    } catch (error) {
      alert("Connection failed. Please try again.");
      setIsLoading(false);
      return;
    }

    const filePromises = files.map((file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve({ name: file.name, data: reader.result });
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
      });
    });

    try {
      const fileDataArray = await Promise.all(filePromises);
      let textContent = "";

      if (files[0].type === "application/pdf") {
        // This is where the PDF is processed
        const pdf = await pdfjsLib.getDocument(URL.createObjectURL(files[0]))
          .promise;

        // Check the number of pages
        if (pdf.numPages > 45) {
          setIsLoading(false);
          setErrorMessage(
            "Error: the file is too large, maximum 45 pages"
          );
          return;
        }

        for (let i = 1; i <= pdf.numPages; i++) {
          const page = await pdf.getPage(i);
          const text = await page.getTextContent();
          text.items.forEach((item) => {
            textContent += item.str + " ";
          });
        }
      }

      socketRef.current.emit("markmap", {
        token,
        files: fileDataArray,
        textContent,
      });
    } catch (error) {
      console.error("Error sending files:", error);
      setErrorMessage(
        "Error the file is too large, maximum 45 pages"
      );
    }
  };

  const handleDownload = () => {
    const svgElement = svgRef.current;
    if (svgElement) {
      const serializer = new XMLSerializer();
      let source = serializer.serializeToString(svgElement);

      if (!source.match(/^<svg[^>]+xmlns="http:\/\/www.w3.org\/2000\/svg"/)) {
        source = source.replace(
          /^<svg/,
          '<svg xmlns="http://www.w3.org/2000/svg"'
        );
      }
      if (!source.match(/^<svg[^>]+"http:\/\/www.w3.org\/1999\/xlink"/)) {
        source = source.replace(
          /^<svg/,
          '<svg xmlns:xlink="http://www.w3.org/1999/xlink"'
        );
      }

      const svg64 = btoa(unescape(encodeURIComponent(source)));
      const image64 = "data:image/svg+xml;base64," + svg64;

      const element = document.createElement("a");
      element.href = image64;
      element.download = files[0].name.split(".")[0] + "-markmap.svg";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    } else {
      console.error("SVG element not found for download.");
    }
  };

  const SEOText = () => (
    <div className="mt-8 p-6 bg-gray-100 rounded-lg shadow-md">
      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">Mind Map Generator</h2>
        <p className="mb-4 text-gray-600">
          Our cutting-edge platform transforms your files into dynamic visual representations. Upload your documents, whether in digital format or image captures, and watch them turn into structured and understandable diagrams. This tool is perfect for breaking down complex information, systematizing concepts, and enhancing your study methodology.
        </p>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          <li className="mb-2">Convert digital and graphic files into dynamic diagrams</li>
          <li className="mb-2">Visually represent abstract ideas</li>
          <li className="mb-2">Systematize concepts and optimize your assimilation process</li>
        </ul>
      </section>
  
      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">Concept Map Creation Process</h2>
        <ol className="list-decimal list-inside mb-4 text-gray-600">
          <li className="mb-2">Import your files (digital or graphic) - with a limit of 45 pages per document</li>
          <li className="mb-2">Our system analyzes and extracts key points</li>
          <li

 className="mb-2">A structured and dynamic diagram is automatically generated</li>
          <li className="mb-2">Adapt and modify the diagram according to your preferences</li>
        </ol>
      </section>
  
      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">Advantages of Conceptual Schemes with AI</h2>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          <li className="mb-2">Facilitates the understanding of intricate topics</li>
          <li className="mb-2">Reduces the time spent on synthesizing information</li>
          <li className="mb-2">Enhances retention and review of key ideas</li>
          <li className="mb-2">Optimal for exam preparation and presentations</li>
          <li className="mb-2">Stimulates visual learning and creative thinking</li>
        </ul>
      </section>
  
      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">Practical Uses</h2>
        <p className="mb-4 text-gray-600">
          Our Cognitive Diagram Generator adapts to various academic and professional needs:
        </p>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          <li className="mb-2">Students: Optimize your notes and improve your study technique</li>
          <li className="mb-2">Teachers: Design visually appealing teaching resources</li>
          <li className="mb-2">Academics: Organize and synthesize data from various sources</li>
          <li className="mb-2">Professionals: Create clear and well-structured presentations</li>
        </ul>
      </section>
  
      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">Highlighted Features</h2>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          <li className="mb-2">Support for digital files and various image formats</li>
          <li className="mb-2">Capability to process documents up to 45 pages</li>
          <li className="mb-2">User-friendly interface</li>
          <li className="mb-2">Adaptable and modifiable diagrams</li>
          <li className="mb-2">Option to export in various formats for easy distribution</li>
        </ul>
      </section>
  
      <section>
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">Revolutionize Your Study Method</h2>
        <p className="text-gray-600">
          Our Cognitive Diagram Generator is not just a tool, it is an ally in your academic development. Redefine the way you process and assimilate information, making your study more efficient and productive. Whether you are preparing for an exam, organizing a project, or trying to understand a complex topic, our interactive diagrams will help you achieve your educational goals. Start creating smart visual representations today and take your learning to the next level!
        </p>
      </section>
    </div>
  );

  return (
    <>
      <div className="container mx-auto p-5 rounded-md shadow-lg mb-20 mt-20">
        <CurrentPath text={"Schema Creator"} />
        <div className="container max-w-4xl mx-auto p-8 rounded">
          <h1 className="text-4xl font-bold mb-6 border-b-4 border-white pb-2 text-center">
            📚 Interactive Schema Creator
          </h1>

          <div className="text-center mb-6 p-4 bg-blue-100 border-l-4 border-blue-500 text-blue-700">
            <p className="font-semibold">
              Transform your documents into interactive mind maps
            </p>
            <p>
              Upload the files you want to get a schema from, either in PDF format or multiple images, and automatically convert them into clear and structured schemas. Ideal for visualizing complex concepts, organizing ideas, and improving your learning process. <strong>Maximum PDFs with 45 pages</strong>
            </p>
          </div>

          <div className="flex flex-row items-center">
            <input
              type="file"
              multiple
              onChange={handleFilesChange}
              className="w-full max-w-lg px-4 py-2 border rounded-lg text-sm leading-normal transition duration-150 ease-in-out sm:text-base lg:max-w-xl"
            />
            <button
              onClick={handleSubmit}
              className="btn btn-primary font-semibold py-2 px-4 rounded transition ease-in-out duration-300 flex flex-row items-center"
            >
              Generate Concept Map
              {isLoading && (
                <div className="flex flex-col justify-center items-center">
                  <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-white"></div>
                </div>
              )}
            </button>
          </div>

          {errorMessage && (
            <div className="alert alert-danger">{errorMessage}</div>
          )}
        </div>
      </div>
      <div className="w-full p-8 shadow-lg">
        {markmapData && (
          <>
            <h2 className="text-2xl font-bold border-b-4 border-white text-center">
              {" "}
              Concept Map
            </h2>
            <button
              onClick={handleDownload}
              className="mt-4 text-center mb-6 p-4 bg-blue-100 border-blue-500 text-blue-700 font-bold py-2 px-4 rounded"
            >
              Download Map
            </button>
            <div
              ref={markmapRef}
              style={{
                width: "100%",
                height: "80vh",
                overflow: "hidden",
                position: "relative",
              }}
            >
              <svg ref={svgRef}></svg>
              <div className="absolute top-1 right-1" ref={toolbarRef}></div>
            </div>
          </>
        )}
        {!token && <SEOText />}
      </div>
    </>
  );
};

export default MarkmapSchemaCreator;