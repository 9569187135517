import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as pdfjsLib from "pdfjs-dist/webpack";
import React, { useEffect, useRef, useState } from "react";
import { FaFilePdf } from "react-icons/fa";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { io } from "socket.io-client";
import CurrentPath from "./CurrentPath.js";
import { downloadPDF } from "./ExamList.js";
import MarkdownPreview from "./MarkdownPreview.js";
import { useSubscription } from "./SubscriptionContext.js";
import useAuth from "./useAuthToken.js";

const apiUrl = process.env.REACT_APP_API_URL;

const ExamSolver = ({ setShowNoTokenModal }) => {
  const [image, setImage] = useState(null);
  const [imagebin, setImagebin] = useState(null);
  const [ocrText, setOcrText] = useState("");
  const [examResult, setExamResult] = useState("");
  const [isStripeModalVisible, setIsStripeModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [examData, setExamData] = useState(null);
  const [isLoadingOcr, setIsLoadingOcr] = useState(false);
  const [showTipsModal, setShowTipsModal] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [pdfPreview, setPdfPreview] = useState(null);
  const { subscriptionType, loading, error, refreshSubscription, remainingCredits } = useSubscription();
  const [token, clearToken] = useAuth();
  const navigate = useNavigate();

  const location = useLocation();
  const stripeModalRef = useRef(null);
  const socketRef = useRef(null);
  const messageCount = useRef(0);

  useEffect(() => {
    const hasRedirected = sessionStorage.getItem("hasRedirectedToPrecios");
    if (subscriptionType === "No Subscription" && !hasRedirected) {
      sessionStorage.setItem("hasRedirectedToPrecios", "true");
      navigate("/view-prices");
    }
    console.log(subscriptionType, navigate)

  }, [subscriptionType, navigate]);

  const connectSocket = (onFormResponse, onGenerationComplete) => {
    const socket = io(apiUrl, {
      reconnectionAttempts: 5,
      reconnectionDelay: 100,
      maxHttpBufferSize: 200 * 1024 * 1024,
      reconnectionDelayMax: 100,
    });

    socket.on("connect", () => {
      console.log("Socket connected.");
    });

    socket.on("disconnect", () => {
      console.log("Socket disconnected.");
    });

    socket.on("form_response", (data) => {
      onFormResponse(data);
    });

    socket.on("form_end", (data) => {
      onGenerationComplete(data);
      socket.disconnect();
    });

    return socket;
  };

  useEffect(() => {
    setExamData({
      ocrText: ocrText,
      response: examResult,
      lastOCR: new Date(),
    });
  }, [ocrText, examResult]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        stripeModalRef.current &&
        !stripeModalRef.current.contains(event.target)
      ) {
        setIsStripeModalVisible(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleImageChange = async (e) => {
    if (subscriptionType === "No Subscription") {
      alert("You need to subscribe to the service to use FotoExamen.");
      setIsLoading(false);
      return;
    }

    if (subscriptionType.includes("Trial") && remainingCredits === 0){
      document.getElementById('trial_ended_modal').showModal();
      return;
    }

    setIsLoadingOcr(true);
    const selectedFile = e.target.files[0];

    if (!token) {
      setShowNoTokenModal(true);
      console.error("No authentication token found.");
      setIsLoadingOcr(false);
      return;
    }

    try {
      if (selectedFile.type === "application/pdf") {
        setImage(null);
        setImagebin(null);
        setPdfUrl(selectedFile);

        const pdf = await pdfjsLib.getDocument(
          URL.createObjectURL(selectedFile)
        ).promise;

        if (pdf.numPages > 45) {
          setIsLoading(false);
          setIsLoadingOcr(false);
          alert(
            "Your exam PDF must have less than 45 pages as it is too large to process."
          );
          return;
        }

        let textContent = "";
        for (let i = 1; i <= pdf.numPages; i++) {
          const page = await pdf.getPage(i);
          const text = await page.getTextContent();
          text.items.forEach((item) => {
            textContent += item.str + " ";
          });
        }
        setOcrText(textContent);
        setPdfPreview(URL.createObjectURL(selectedFile));
        setIsLoadingOcr(false);
      } else if (selectedFile.type.startsWith("image/")) {
        setPdfUrl(null);
        setPdfPreview(null);
        setImage(URL.createObjectURL(selectedFile));
        setImagebin(selectedFile);

        const formData = new FormData();
        formData.append("file", selectedFile);
        const response = await fetch(apiUrl + "/api/easyexams/ocr", {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        });
        if (response.ok) {
          const responseData = await response.json();
          if (responseData.nowpay) {
            window.location.href = "https://www.fotoexamen.com/precios.html";
          } else {
            setIsLoadingOcr(false);
            setOcrText(responseData.texts);
          }
        } else {
          setIsLoadingOcr(false);
          alert(
            "Text could not be detected in the image. Make sure your image is smaller than 20 MB and has one of the following formats: .png, .jpeg, .gif, or .webp."
          );
        }
      } else {
        alert("Unsupported file type. Please upload an image or a PDF file.");
        setIsLoadingOcr(false);
      }
    } catch (error) {
      console.error("Error processing the file:", error);
      setIsLoadingOcr(false);
    }
  };

  const waitForSocketConnection = (socket) => {
    return new Promise((resolve, reject) => {
      const maxAttempts = 5;
      let attempts = 0;

      const checkSocketConnection = () => {
        if (socket.connected) {
          resolve();
        } else {
          attempts += 1;
          if (attempts >= maxAttempts) {
            reject(new Error("Socket connection failed"));
          } else {
            setTimeout(checkSocketConnection, 200); // Retry after 200ms
          }
        }
      };

      checkSocketConnection();
    });
  };

  const handleResolveExam = async () => {
    setIsLoading(true);
    if (!token) {
      setShowNoTokenModal(true);
      console.error("No authentication token found.");
      setIsLoading(false);
      return;
    }

    socketRef.current = connectSocket(
      (data) => {
        messageCount.current += 1;
        if (messageCount.current % 100 === 0) {
          setExamResult(data?.form_response);
        }
        if (data?.thinking) {
          setExamResult(data?.thinking);
        }
      },
      (data) => {
        setExamResult(data?.form_end);
        setIsLoading(false);
      }
    );

    try {
      await waitForSocketConnection(socketRef.current);
      refreshSubscription();
    } catch (error) {
      alert(
        "Connection could not be established. Please try again."
      );
      setIsLoading(false);
      return;
    }

    const data = {
      token: token,
      form: {
        ocrText: ocrText,
      },
      files: {},
    };

    if (imagebin) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const base64Data = e.target.result.split(",")[1]; // Get the base64 string without the prefix
        data.files.image = base64Data;
        socketRef.current.emit("form", data);
      };
      reader.readAsDataURL(imagebin);
    } else if (pdfUrl) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const base64Data = e.target.result.split(",")[1]; // Get the base64 string without the prefix
        data.files.pdf = base64Data;
        socketRef.current.emit("form", data);
      };
      reader.readAsDataURL(pdfUrl);
    } else {
      socketRef.current.emit("form", data);
    }
  };

  const showStripeModal = () => {
    setIsStripeModalVisible(true);
  };

  const toggleTipsModal = () => {
    setShowTipsModal(!showTipsModal);
  };

  const SEOText = () => (
    <div className="mt-8 p-6 bg-gray-100 rounded-lg shadow-md">
      <h2 className="text-4xl font-bold mb-6 text-gray-800">Solve Exams with AI: Revolutionize Your Learning</h2>
      
      <section className="mb-8">
        <h3 className="text-3xl font-semibold mb-4 text-gray-700">Solve Exams with AI: Your Personal Study Assistant</h3>
        <p className="mb-4 text-gray-600">
          FotoExamen uses advanced artificial intelligence to help you solve exams in any subject. No more frustrating hours trying to decipher complex problems. Our AI analyzes your exams, provides step-by-step solutions, and helps you understand the underlying concepts.
        </p>
        <ul className="list-disc list-inside mb-4 text-gray

-600">
          <li className="mb-2">Instant resolution of exams in mathematics, sciences, humanities, and more</li>
          <li className="mb-2">Detailed explanations that improve your understanding of the topic</li>
          <li className="mb-2">Adapted to all educational levels: high school, college, and university</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">Past Exams: Prepare with Confidence</h2>
        <p className="mb-4 text-gray-600">
          With FotoExamen, you can practice with past exams and be better prepared for your tests. Our AI not only gives you the answers but shows you how to arrive at them, helping you master the common patterns and question styles in your exams.
        </p>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          <li className="mb-2">Access a wide library of past exams</li>
          <li className="mb-2">Receive detailed solutions for each question</li>
          <li className="mb-2">Identify your areas of improvement and focus your study</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">Do Homework with AI: Optimize Your Study Time</h2>
        <p className="mb-4 text-gray-600">
          Homework will no longer be a burden with FotoExamen. Our AI guides you through your assignments, providing the support you need to complete them efficiently and effectively. Whether it's a math problem, a literature essay, or a science project, FotoExamen is here to help.
        </p>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          <li className="mb-2">Real-time assistance for your daily homework</li>
          <li className="mb-2">Suggestions and corrections to improve your work</li>
          <li className="mb-2">Learn as you go: better understand concepts as you apply them</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">How Does FotoExamen Work?</h2>
        <ol className="list-decimal list-inside mb-4 text-gray-600">
          <li className="mb-2">Upload a photo of your exam or a pdf, assignment, or problem</li>
          <li className="mb-2">Our AI analyzes the content in seconds</li>
          <li className="mb-2">Receive detailed solutions and clear explanations</li>
          <li className="mb-2">Learn from the answers and improve your understanding</li>
        </ol>
      </section>

      <section>
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">Transform Your Learning Experience with FotoExamen</h2>
        <p className="text-gray-600">
          Whether you're struggling with difficult exams, trying to understand complex concepts, or just looking for a more efficient way to do your homework, FotoExamen is your solution. Our AI is designed to adapt to your learning style and help you reach your full academic potential. Join thousands of students who are already experiencing the power of AI in their education with FotoExamen!
        </p>
      </section>
    </div>
  );

  return (
    <div className="container mx-auto p-5 rounded-md shadow-lg mb-20 mt-20">
      <CurrentPath text="Solve your exam" />
      <div className="container max-w-4xl mx-auto p-8 bg-white rounded">
        <div className="mb-8 text-center max-w-xl mx-auto">
          <h1 className="text-4xl font-bold mb-6 border-b-4 border-white pb-2">
            ✍🏻 Solve your exam
          </h1>
          <p>
            Use our custom artificial intelligence to solve all your exams, upload an image or a PDF of your exam or assignment. Edit the detected text if you find any errors and press solve exam when you are ready. You can view the result or download it in PDF format.
          </p>
        </div>

        <div className="mb-8 space-y-6">
          <h2 className="text-2xl font-bold mb-4">Upload your exam:</h2>
          <div className="flex justify-center items-center">
            <input
              type="file"
              accept="image/*,application/pdf"
              capture="environment"
              className="hidden"
              onChange={handleImageChange}
              id="photo-capture"
            />
            <label
              htmlFor="photo-capture"
              className="btn btn-primary flex justify-center items-center bg-green-200 hover:bg-green-300 text-green-700 font-semibold py-3 px-6 rounded cursor-pointer transition ease-in-out duration-300"
            >
              <FontAwesomeIcon icon={faCamera} className="fa-lg mr-2" />
              Take Photo
            </label>
          </div>

          <div className="flex justify-center items-center">
            <input
              type="file"
              accept="image/*,application/pdf"
              className="file-input w-full max-w-xs"
              onChange={handleImageChange}
            />
          </div>
        </div>

        {(image || pdfPreview) && (
          <div className="flex items-center justify-center mb-16">
            <dialog id="previewModal" className="modal">
              <div className="modal-box">
                {image && (
                  <img
                    src={image}
                    alt="Uploaded exam"
                    className="max-w-full h-auto"
                  />
                )}
                {pdfPreview && (
                  <iframe
                    src={pdfPreview}
                    title="PDF Preview"
                    className="w-full h-96"
                  ></iframe>
                )}
                <div className="modal-action">
                  <button
                    className="btn"
                    onClick={() =>
                      document.getElementById("previewModal").close()
                    }
                  >
                    Close
                  </button>
                </div>
              </div>
            </dialog>
            <div
              onClick={() =>
                document.getElementById("previewModal").showModal()
              }
              className="btn mt-3 rounded h-64 w-full object-cover object-top flex items-center justify-center overflow-hidden"
              style={{ background: "#f0f0f0" }}
            >
              {image ? (
                <img
                  src={image}
                  alt="Uploaded exam"
                  className="max-w-full h-auto"
                />
              ) : (
                <FaFilePdf size={64} />
              )}
            </div>
          </div>
        )}

        {ocrText !== "" && (
          <div className="mb-8">
            <h2 className="text-2xl font-bold mb-4">Detected text:</h2>
            <textarea
              className="w-full h-64 border-2 border-gray-300 p-4 rounded-lg whitespace-pre-line"
              value={ocrText}
              onChange={(e) => setOcrText(e.target.value)}
            ></textarea>
          </div>
        )}

        {isLoadingOcr && (
          <div className="flex flex-col justify-center items-center mb-4">
            <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-600 my-4"></div>
            <p className="text-lg font-semibold">
              Detecting text from file or image...
            </p>
          </div>
        )}
        {isLoading && (
          <div className="flex flex-col justify-center items-center mb-4">
            <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-600 my-4"></div>
            <p className="text-lg font-semibold">
              Solving exam with our advanced AI...
            </p>
          </div>
        )}
        <div className="divider" />
        <div className="mb-8">
          <div className="flex justify-start items-center space-x-4 flex-col md:flex-row">
            <button
              className={`btn btn-primary flex justify-center items-center w-full md:w-auto ${                !ocrText.trim() ||
                (isLoading && "opacity-50 cursor-not-allowed")
              }`}
              onClick={handleResolveExam}
              disabled={!ocrText.trim() || isLoading}
            >
              Solve Exam
            </button>
            <button
              onClick={toggleTipsModal}
              className="btn w-full md:w-auto"
            >
              Usage tips
            </button>
          </div>

          {examResult !== "" && (
            <div className="mt-8">
              <h2 className="text-2xl font-bold mb-4">Exam result:</h2>
              <button
                onClick={() => downloadPDF(examData)}
                className="mb-2 bg-red-200 hover:bg-red-300 text

-red-800 font-semibold py-2 px-4 rounded transition ease-in-out duration-300 flex items-center justify-center mt-4"
              >
                <FaFilePdf size={24} className="mr-2" /> Download PDF
              </button>
              <div className="border-2 border-gray-300 p-4 rounded-lg whitespace-pre-line" id="result">
                <MarkdownPreview content={examResult} />
              </div>
            </div>
          )}
          
        </div>
        {isStripeModalVisible && (
          <div
            id="stripeModal"
            ref={stripeModalRef}
            className="modal fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center p-4"
          >
            <div className="modal-content bg-white p-2 sm:p-6 rounded-lg shadow-lg text-center overflow-auto max-h-full">
              <div className="modal fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center p-4">
                <div className="modal-content bg-white p-2 sm:p-6 rounded-lg shadow-lg text-center overflow-auto max-h-full">
                  <h2 className="text-lg sm:text-xl font-bold mb-4">
                    You've used up your free credits
                  </h2>
                  <p className="mb-4 text-sm sm:text-base">
                    Want to keep using FotoExamen? Discover the amazing benefits
                    of our premium plans:
                  </p>
                  <ul className="list-disc list-inside text-left mb-4 text-sm sm:text-base">
                    <li className="mb-2">
                      🚀 Unlimited access to exam resolutions.
                    </li>
                    <li className="mb-2">
                      💡 Priority support to help you when you need it most.
                    </li>
                    <li className="mb-2">
                      🔐 Priority processing of your requests.
                    </li>
                    <li className="mb-2">
                      🎓 Advanced tools for more effective learning.
                    </li>
                    <li className="mb-2">
                      💼 Additional resources to prepare you for your exams.
                    </li>
                  </ul>
                  <p className="mb-4 font-bold text-sm sm:text-base">
                    Invest in your academic success, choose a premium plan today
                    and feel the difference!
                  </p>
                  <button
                    id="view-prices"
                    className="bg-green-500 hover:bg-green-700 text-white font-bold py-3 px-4 rounded transition duration-300 ease-in-out shadow-lg transform hover:scale-105"
                    onClick={showStripeModal}
                  >
                    View plans 💲
                  </button>
                </div>
              </div>

              <button onClick={showStripeModal}>View plans 💲</button>
            </div>
          </div>
        )}

        {showTipsModal && (
          <div className="fixed inset-0 z-50 overflow-auto bg-smoke-light flex">
            <div className="relative p-8 bg-white w-full max-w-lg m-auto flex-col flex rounded-lg shadow-lg">
              <div className="flex justify-between items-center pb-3">
                <p className="text-2xl font-bold">
                  Tips for using the platform
                </p>
                <div className="cursor-pointer z-50" onClick={toggleTipsModal}>
                  <svg
                    className="fill-current"
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                  >
                    <path d="M12.3 11.7l-3.3-3.3 3.3-3.3L11.7 5l-3.3 3.3L5 5 4.3 5.7l3.3 3.3-3.3 3.3L5 12.3l3.3-3.3 3.3 3.3z" />
                  </svg>
                </div>
              </div>
              <ul className="list-disc list-inside text-left text-md lg:text-lg mb-4 leading-relaxed">
                <li>
                  Clear photo: Make sure to take clear photos where the text is completely horizontal. This improves the accuracy with which the platform can interpret and process the information. Also, the image must not exceed 20Mb and the accepted formats are: 'png', 'jpeg', 'gif', 'webp'
                </li>
                <li>
                  Repeat the process: Do not hesitate to resolve the same photo multiple times. Sometimes, the platform may offer slightly different results, so it is useful to do several tests to get the best answer.
                </li>
                <li>
                  Reasoning in math: In math exams, it is crucial not to rely solely on automatic answers. Take the reasoning that the platform offers you and then verify the calculations manually to ensure they are correct.
                </li>
                <li>
                  Use the{" "}
                  <Link
                    to="/tutors"
                    className="text-blue-600 hover:text-blue-800 underline hover:underline decoration-blue-500 hover:decoration-blue-700 transition duration-300 ease-in-out"
                  >
                    tutors
                  </Link>
                  : If you have any doubts about an answer, take advantage of the platform's tutor section. You can upload the exercise in question and ask all the questions you need to clarify your doubts and better understand the material.
                </li>
              </ul>
              <div className="flex justify-end pt-2">
                <button
                  className="px-4 bg-blue-500 hover:bg-blue-700 text-white p-3 rounded-lg text-sm lg:text-base transition duration-300 ease-in-out"
                  onClick={toggleTipsModal}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      {!token && <SEOText />}
    </div>
  );
};

export default ExamSolver;