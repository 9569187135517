import React from 'react'

export default function FAQ() {
  return (
    <>
      <div className="container mx-auto px-4 py-8">
        <h2 className="text-2xl font-bold mb-6">Frequently Asked Questions</h2>
        <div className="space-y-4">
          <div className="collapse collapse-plus bg-base-200">
            <input type="radio" name="my-accordion-3" /> 
            <div className="collapse-title text-xl font-medium">
              How can I cancel my subscription?
            </div>
            <div className="collapse-content"> 
              <p>You can cancel your subscription at any time from your account settings. The cancellation will be effective at the end of the current billing period.</p>
            </div>
          </div>
          <div className="collapse collapse-plus bg-base-200">
            <input type="radio" name="my-accordion-3" /> 
            <div className="collapse-title text-xl font-medium">
              What payment methods are accepted?
            </div>
            <div className="collapse-content"> 
              <p>We accept credit and debit cards from major brands, as well as PayPal.</p>
            </div>
          </div>
          <div className="collapse collapse-plus bg-base-200">
            <input type="radio" name="my-accordion-3" /> 
            <div className="collapse-title text-xl font-medium">
              Do you offer refunds?
            </div>
            <div className="collapse-content"> 
              <p>Yes, we offer a full refund if you cancel within the first 15 days of your subscription.</p>
            </div>
          </div>
          <div className="collapse collapse-plus bg-base-200">
            <input type="radio" name="my-accordion-3" /> 
            <div className="collapse-title text-xl font-medium">
              What does my subscription include?
            </div>
            <div className="collapse-content"> 
              <p>Your subscription includes full access to all our premium features, regular updates, and priority customer support.</p>
            </div>
          </div>
          <div className="collapse collapse-plus bg-base-200">
            <input type="radio" name="my-accordion-3" /> 
            <div className="collapse-title text-xl font-medium">
              How often is billing done?
            </div>
            <div className="collapse-content"> 
              <p>We offer monthly and annual billing options. You can choose the one that best suits your needs.</p>
            </div>
          </div>
          <div className="collapse collapse-plus bg-base-200">
            <input type="radio" name="my-accordion-3" /> 
            <div className="collapse-title text-xl font-medium">
              Is there a free trial period?
            </div>
            <div className="collapse-content"> 
              <p>Yes, we offer a 3-day free trial period and you can also request a refund within the first 15 days if you are not satisfied with our service.</p>
            </div>
          </div>
          <div className="collapse collapse-plus bg-base-200">
            <input type="radio" name="my-accordion-3" /> 
            <div className="collapse-title text-xl font-medium">
              Are there any advantages to choosing the annual subscription?
            </div>
            <div className="collapse-content"> 
              <p>Yes, by subscribing to the annual plan, the price is more than 50% cheaper, which is equivalent to more than 6 months free compared to the regular monthly price. Be sure to select the annual plan to save a significant amount of money. This is our way of thanking you for your trust in our service and allows us to reinvest your money to improve the product.</p>
            </div>
          </div>

          <div className="collapse collapse-plus bg-base-200">
            <input type="radio" name="my-accordion-3" /> 
            <div className="collapse-title text-xl font-medium">
              How can I manage my subscription?
            </div>
            <div className="collapse-content"> 
              <p>You can manage your subscription at any time. Go to the top of the page when you're logged in and click on "Billing". You will be redirected to the Stripe billing portal where you can change plans, download invoices, and cancel your subscription if you wish.</p>
            </div>
          </div>

          <div className="collapse collapse-plus bg-base-200">
            <input type="radio" name="my-accordion-3" /> 
            <div className="collapse-title text-xl font-medium">
              Is the payment process secure?
            </div>
            <div className="collapse-content"> 
              <p>Yes, our payment service is secure. We use Stripe for financial processing and do not store any information about your card. Stripe guarantees bank-level security standards.</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
