import React from "react";
import { useSubscription } from "./SubscriptionContext";
export default function Footer() {
  const { subscriptionType, loading, error } = useSubscription();

  return (
    <>
      <footer className="bg-gradient-to-r from-blue-500 to-purple-600 text-white p-10">
        <div className="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-10">
          <div className="footer-section">
            <h3 className="text-xl font-semibold mb-4">Quick links</h3>
            <ul>
              <li className="my-2 hover:underline">
                <a href="https://www.photoexamai.com">Home</a>
              </li>
              <li className="my-2 hover:underline">
                <a href="https://www.photoexamai.com/data-and-privacy-policy.html">
                Data and Privacy Policy
                </a>
              </li>
              <li className="my-2 hover:underline">
                <a href="https://www.photoexamai.com/contact.html">Contact</a>
              </li>
              <li className="my-2 hover:underline">
                <a href="https://www.photoexamai.com/payments.html" title="Payments">
                  Payments
                </a>
              </li>
              <li className="my-2 hover:underline">
                <a href="https://www.photoexamai.com/terms-of-use.html" title="Terms of use">
                Terms of use
                </a>
              </li>
            </ul>
          </div>
          <div className="footer-section">
            <h3 className="text-xl font-semibold mb-4">Follow us</h3>
            <div className="social-icons flex flex-col">
              <a
                href="https://twitter.com/fotoexamen"
                rel="noreferrer"
                target="_blank"
                title="Twitter"
                className="hover:text-blue-400 transition-colors duration-300"
              >
                <i className="fab fa-twitter"></i>
                Twitter
              </a>
              <a
                href="https://www.tiktok.com/@fotoexamen"
                rel="noreferrer"
                target="_blank"
                title="Tiktok"
                className="hover:text-blue-400 transition-colors duration-300"
              >
                <i className="fab fa-tiktok"></i>
                Tiktok
              </a>
              <a
                href="https://www.instagram.com/fotoexamen"
                rel="noreferrer"
                target="_blank"
                title="Instagram"
                className="hover:text-blue-400 transition-colors duration-300"
              >
                <i className="fab fa-instagram"></i>
                Instagram
              </a>
            </div>
          </div>
          <div className="footer-section">
            <h3 className="text-xl font-semibold mb-4">Contact</h3>
            <p>
              Email:
              <a
                href="mailto:info@fotoexamen.com"
                rel="noreferrer"
                className="underline hover:text-blue-400 transition-colors duration-300"
                target="_blank"
                title="Email"
              >
                info@fotoexamen.com
              </a>
            </p>
            <button
              className="btn btn-accent"
              onClick={() => document.getElementById("my_modal_3").showModal()}
            >
              ⭐️ Leave Us Your Feedback
            </button>
            <dialog id="my_modal_3" className="modal">
              <div className="modal-box">
                <form method="dialog">
                  {/* if there is a button in form, it will close the modal */}
                  <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2 text-black">
                    ✕
                  </button>
                </form>
                <script
                  type="text/javascript"
                  src="https://widget.senja.io/js/iframeResizer.min.js"
                ></script>
                <iframe
                  id="senja-collector-iframe"
                  src="https://senja.io/p/foto-examen/r/XQSTBa?mode=embed&nostyle=true"
                  allow="camera;microphone"
                  title="Senja form"
                  frameBorder="0"
                  scrolling="no"
                  width="100%"
                  height="700"
                ></iframe>
                <form method="dialog">
                  {/* if there is a button in form, it will close the modal */}
                  <button className="btn btn-md btn-ghost text-black">
                    Close
                  </button>
                </form>
              </div>
            </dialog>
          </div>
        </div>
        <div className="copyright mt-12 text-center text-white">
          © 2024 PhotoExam. All rights reserved.
        </div>
      </footer>
    </>
  );
}
