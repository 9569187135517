import * as pdfjsLib from "pdfjs-dist/webpack";
import { default as React, useState } from "react";
import CurrentPath from "./CurrentPath";
import MarkdownPreview from "./MarkdownPreview";
import { useSubscription } from "./SubscriptionContext";
import useAuth from "./useAuthToken";

const apiUrl = process.env.REACT_APP_API_URL;
const Summary = ({ setShowNoTokenModal }) => {
  const [file, setFile] = useState(null);
  const [text, setText] = useState("");
  const [summary, setSummary] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { subscriptionType, loading, error, refreshSubscription, remainingCredits } = useSubscription();
  const [token, clearToken] = useAuth();

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    setText(""); // Clear the text to hide the text area
    setSummary("");
  };

  const handleTextChange = (event) => {
    setText(event.target.value);
    setFile(null); // Clear the file to hide the file input
    setSummary("");
  };

  const handleSummarize = async () => {
    if (subscriptionType === "No Subscription") {
      alert("You need a subscription to use the service.");
      return;
    }

    if (subscriptionType.includes("Trial") && remainingCredits === 0){
      document.getElementById('trial_ended_modal').showModal();
      return;
    }

    if (!file && !text.trim()) {
      alert(
        "Please upload a file or write some text before generating a summary."
      );
      return;
    }

    if (!token) {
      setShowNoTokenModal(true);
      console.error("No authentication token found.");
      return;
    }

    setIsLoading(true);
    setErrorMessage("");

    const formData = new FormData();
    let pdftext = "";

    if (file) {
      if (file.type === "application/pdf") {
        try {
          const pdf = await pdfjsLib.getDocument(URL.createObjectURL(file))
            .promise;
          let textContent = "";
          for (let i = 1; i <= pdf.numPages; i++) {
            const page = await pdf.getPage(i);
            const text = await page.getTextContent();
            text.items.forEach((item) => {
              textContent += item.str + " ";
            });
          }
          pdftext = textContent;
          formData.append("text", pdftext);
        } catch (error) {
          console.error("Error extracting text from PDF:", error);
          setErrorMessage(
            "Error extracting text from PDF. Please try another file."
          );
          setIsLoading(false);
          return;
        }
      } else if (file.type.startsWith("image/")) {
        formData.append("files", file);
      } else {
        alert("Unsupported file type. Upload an image or PDF file.");
        setIsLoading(false);
        return;
      }
    } else {
      formData.append("text", text);
    }

    try {
      const response = await fetch(`${apiUrl}/api/easyexams/summary`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });
      const data = await response.json();
      if (data?.too_long) {
        setErrorMessage(
          "The text is too long. Please upload another smaller file or enter less text. You have uploaded: " +
            data?.total_tokens +
            " tokens, and the maximum is " +
            data?.max_tokens +
            " tokens."
        );
        return;
      }

      if (!response.ok) {
        throw new Error(data.message || "Error fetching the summary.");
      }

      setSummary(data.summary);
      refreshSubscription();
    } catch (error) {
      console.error("Error summarizing text:", error);
      setErrorMessage(
        "Error, please try again or with another smaller file. If the problem persists, contact us."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const SEOText = () => (
    <div className="mt-8 p-6 bg-gray-100 rounded-lg shadow-md">
      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">
          Smart Summary Generator
        </h2>
        <p className="mb-4 text-gray-600">
          Get concise and efficient summaries of your documents with our Summary
          Generator. Upload a PDF document, multiple images, or enter text
          directly to receive a clear and precise summary. This tool is ideal
          for students, professionals, and anyone who needs to quickly
          synthesize information.
        </p>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          <li className="mb-2">Support for PDF documents and direct text</li>
          <li className="mb-2">Quick and accurate summaries</li>
          <li className="mb-2">Ideal for various information needs</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">
          Easy to Use and Flexible
        </h2>
        <p className="mb-4 text-gray-600">
          Our summary generator is extremely easy to use. Simply upload your PDF
          document, images, or write the text directly in our editor. The tool
          will process the content and generate a summary that effectively
          captures the key points.
        </p>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          <li className="mb-2">Intuitive and easy-to-navigate interface</li>
          <li className="mb-2">Fast document processing</li>
          <li className="mb-2">Summary generated in seconds</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">
          Versatile for Different Types of Documents
        </h2>
        <p className="mb-4 text-gray-600">
          Our summary generator is versatile and supports a wide range of
          documents. Whether you are working with academic texts, work reports,
          or any other type of content, our tool can help you get a precise and
          useful summary.
        </p>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          <li className="mb-2">
            Compatible with PDFs and multiple image formats
          </li>
          <li className="mb-2">Adapted for different areas and uses</li>
          <li className="mb-2">
            Perfect for students, professionals, and more
          </li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">
          Benefits of the Summary Generator
        </h2>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          <li className="mb-2">Time-saving in synthesizing information</li>
          <li className="mb-2">Improved understanding of key topics</li>
          <li className="mb-2">Reduction of information overload</li>
          <li className="mb-2">Quick access to the most important points</li>
          <li className="mb-2">Ease of use on any device</li>
        </ul>
      </section>

      <section>
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">
          Optimize Your Information Processing
        </h2>
        <p className="text-gray-600">
          Our Summary Generator is the ideal tool for optimizing information
          processing. Whether you need to summarize academic material, work
          documents, or any other type of content, our platform is designed to
          help you extract the key points quickly and efficiently. Start using
          the Summary Generator today and transform the way you handle
          information!
        </p>
      </section>
    </div>
  );

  return (
    <div className="container mx-auto p-5 rounded-md shadow-lg mb-20 mt-20">
      <CurrentPath text={"Text Summary"} />
      <div className="container max-w-4xl mx-auto p-8 rounded">
        <h1 className="text-4xl font-bold border-b-4 border-white pb-2 text-center">
          📄 Summary Generator
        </h1>
        <p className="mb-6">
          Upload a PDF document or multiple images, or you can enter text
          directly to get a concise and efficient summary.
        </p>
        <h2 className="text-2xl font-bold mb-6 border-b-4 border-white pb-2 text-center">
          Write a text or upload a PDF
        </h2>
        <div>
          {!file && (
            <textarea
              className="textarea textarea-bordered h-24 w-full"
              placeholder="Write your text here..."
              value={text}
              onChange={handleTextChange}
            ></textarea>
          )}

          {!text && (
            <input
              type="file"
              onChange={handleFileChange}
              className="input input-bordered w-full mt-4"
            />
          )}
        </div>

        <button onClick={handleSummarize} className="btn btn-primary mt-4">
          Generate Summary
        </button>

        {isLoading && (
          <div className="flex flex-col justify-center items-center">
            <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-green-600 my-4"></div>
            <p className="text-lg font-semibold">Processing...</p>
          </div>
        )}

        {errorMessage && (
          <div className="shadow-lg mt-4 p-4 rounded">
            <div>
              <span>{errorMessage}</span>
            </div>
          </div>
        )}

        {summary && (
          <div className="border-2 border-gray-300 p-4 rounded-lg whitespace-pre-line mt-8">
            <MarkdownPreview content={summary} />
          </div>
        )}
      </div>
      {!token && <SEOText />}
    </div>
  );
};

export default Summary;
